import { authRequest } from './api/auth';

class UpChatDataService {
    // getContactMessage(last_name,first_name,email,phone){
    //     return authRequest.get(`/chatwoot/messages/timeline/?first_name=${first_name}&last_name=${last_name}&email=${email}&phone=${phone}`);
    // }
    getContactMessage(id){
        return authRequest.get(`/chatwoot/messages/timeline/?id=${id}`);
    }
    loginUpchat(user_id) {
        return authRequest.get(`/chatwoot/login/${user_id}`);
    }
    getWsInfo() {
        return authRequest.get('/chatwoot/wsinfo/');
    }
    updateContact(data) {
        return authRequest.post('/chatwoot/contact/', data);
    }    
}

export default new UpChatDataService();